import { useEffect, useState } from 'react';

import SiteSummary from './components/site-summary/site-summary';
import KerrieLink from './components/kerrie-link/kerrie-link';

import Background from './images/Background.png';
import CloudsBack from './images/CloudsBack.png';
import CloudsFront from './images/CloudsFront.png';
import Foreground from './images/Foreground.png';
import Midground from './images/Midground.png';
import Mountains from './images/Mountains.png';
import SkyBox from './images/SkyBox.png';

import './App.css';

function App() {
  const rootStyle = { backgroundImage: `url(${SkyBox})` };

  const [mouseOffset, setMouseOffset] = useState({});

  useEffect(() => {
    const handleMouseMove = (event) => {
      let h = window.innerHeight/2;
      let w = window.innerWidth/2;
      setMouseOffset({ x: (event.clientX - w)/w, y: (event.clientY - h)/-h });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener(
        'mousemove',
        handleMouseMove
      );
    };
  }, []);

  return (
    <div id="app-root">
      <div id="background-box" className="App" style={rootStyle}>
        <div className="scale-box">
          <img className="mountain background" src={Mountains} alt=""
            style={{ "transform": "translate(" + (-50 + (-mouseOffset.x * 3)) + "%, " + (-10 + mouseOffset.y * -1.5) + "%)" }} />
          <img className="background" src={Background} alt=""
            style={{ "transform": "translate(" + (-50 + (-mouseOffset.x * 1)) + "%, " + (-3 + mouseOffset.y * -1) + "%)" }} />
          <img className="background" src={Midground} alt=""
            style={{ "transform": "translate(" + (-50 + (mouseOffset.x * 1)) + "%, " + (-10 + mouseOffset.y * 1) + "%)" }} />
          <img className="foreground" src={Foreground} alt=""
            style={
              {
                "top":  (mouseOffset.y * 2 + 15) + "%",
                "right": -30 + (mouseOffset.x * -4) + "vh"
              }
            } />
          <div className="container">
            <div className="sliding-background" style={{
              backgroundImage: `url(${CloudsBack})`,
              marginTop: (mouseOffset.y * 2 + 100) + "px"
            }} />
          </div>
          <div className="container">
            <div className="sliding-background" style={{
              backgroundImage: `url(${CloudsFront})`,
              marginTop: (mouseOffset.y * 5 + 100) + "px"
            }} />
          </div>
        </div>
      </div>
      <div id="content-box">
        < SiteSummary />
        <div className="footer">
          < KerrieLink />
          <div className="copyright">©2022 Hungry Guy Games</div>
        </div>
      </div>
    </div>
  );
}

export default App;
