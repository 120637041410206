import React from 'react';
import './kerrie-link.css';
import kerrie from '../../images/kerrie.png';

const KerrieLink = () => (
  <a className="kerrie-link" href='https://linktr.ee/mskerrbear'>    
    <img src={kerrie} className="kerrie-img" alt="logo" /><div>Background by Kerrie Lake</div>
  </a>
);

KerrieLink.propTypes = {};

KerrieLink.defaultProps = {};

export default KerrieLink;
