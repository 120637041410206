import React from 'react';
import SocialsBar from '../socials-bar/socials-bar';
import SweepSerfLogo from '../../images/SweepSerfLogo.png';
import './site-summary.css';

const SiteSummary = () => (
  <div className="site-summary">
    <div className="outer">
      <div className="inner">
        <img src={SweepSerfLogo} className="logo" alt="logo" />
      </div>
    </div>
    <br />
    <div className='description'>
      Sweep Serf is a puzzle platformer that is currently in development. It explores a fresh new spin on minesweeper by incorporating platforming, item drops, crafting, and more!
      <br />
      Please support the game by wishlisting on <a href="https://store.steampowered.com/app/2228480/Sweep_Serf">Steam</a>
    </div>
    < SocialsBar />
  </div>
);

SiteSummary.propTypes = {};

SiteSummary.defaultProps = {};

export default SiteSummary;
