import React from 'react';
import {AiFillTwitterSquare} from 'react-icons/ai'
import {BiSolidSquareRounded} from 'react-icons/bi'
import {FaSteam} from 'react-icons/fa'
import './socials-bar.css';

const SocialsBar = () => (
  <div className="socials-bar">
    <div className='social-link twitter' >
      <a className="icon" href='https://twitter.com/Final_Turn_Dev'>
        <AiFillTwitterSquare />
      </a>
      <br />
      <a className="text" href='https://twitter.com/Final_Turn_Dev'>
        Twitter
      </a>
    </div>
    <div className='social-link steam' >
      <a className="icon" href='https://store.steampowered.com/app/2228480/Sweep_Serf'>
        <FaSteam />
      </a>
      <br />
      <a className="text" href='https://store.steampowered.com/app/2228480/Sweep_Serf'>
        Steam
      </a>
    </div>
    <div className='social-link bluesky' >
      <a className="icon" href='https://bsky.app/profile/finalturn.bsky.social'>
        <BiSolidSquareRounded />
      </a>
      <br />
      <a className="text" href='https://bsky.app/profile/finalturn.bsky.social'>
      Bluesky
      </a>
    </div>
  </div>
);

SocialsBar.propTypes = {};

SocialsBar.defaultProps = {};

export default SocialsBar;
